<template>
  <div class="innerContent"
    style="
      height: calc(100vh);
      width: 100%;
      min-width: 85%;
      max-width: 100%;
      background: var(--v-greyRaised-base) !important;
    ">
    <v-card flat style="background: var(--v-greyRaised-base) !important; background-color: var(--v-greyRaised-base) !important">
      <v-card-text v-if="!booking.stockProvider">
        <div class="container">
          <h3 style="color: grey">
            Please first select a "Stock Provider" under the "Relationships Tab"
          </h3>
        </div>
      </v-card-text>
      <v-card-text style="min-height: 70vh" class="mt-0" v-else>
        <div v-if="!loading">
          <v-row>
            <v-col cols="12" sm="3" md="2">
              <v-list dense subheader>
                <v-text-field placeholder="Search" prepend-inner-icon="search" dense outlined rounded clearable v-model="searchCustomer" hide-details />
                <v-divider></v-divider>
                <v-progress-linear indeterminate color="primary" v-if="loadingItems"/>
                <div v-if="orders.length == 1">
                  <v-list-item v-for="container in orderItems" :key="container.id" @click="setActiveContainer(container)"
                    :style="{ background: selectedContainer && container.id == selectedContainer.id ? 'rgba(114, 159, 217, 0.2)' : '', }" >
                    <v-list-item-content>
                      <v-list-item-title> {{ container.ctoNo }} </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ container.assignedStock }}/{{ container.totalPallets || 20 }} Pallets
                        <span v-if="container.cartons"> - {{ container.cartons }} Cartons </span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <span style="font-size: 11px" v-for="product in container.containerProducts" :key="product.id" > - {{ product.product.name }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-progress-circular :value="container.assignedProgress" size="20" :color=" container.assignedProgress < 20 ? 'red' : container.assignedProgress < 50 ? 'orange' : container.assignedProgress < 100 ? 'blue' : 'success' "/>
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </v-list>
            </v-col>
            <v-col cols="12" sm="9" md="10">
              <v-card flat v-if="selectedContainer && selectedContainer.id" :loading="loadingStock" style="border-radius: 20px; background-color: var(--v-component-base) !important;">
                <v-toolbar flat dense color="modal" v-if="selectedContainer">
                  <v-toolbar-title> {{ selectedContainer.ctoNo }} </v-toolbar-title>
                  <v-chip outlined dense style="border: none">
                    <v-chip v-if="booking.portOfLoadValue" small outlined style="border: none">
                      <v-avatar size="24" left>
                        <v-img contain v-if="booking.portOfLoadValue" :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfLoadValue.substring(0,2).toLowerCase()}.svg`" ></v-img>
                      </v-avatar>
                      {{ booking.portOfLoadValue }}
                    </v-chip>
                    <v-icon small class="mx-1">arrow_forward</v-icon>
                    <v-chip v-if="booking.finalDestinationValue" small outlined style="border: none">
                      <v-avatar size="24" left>
                        <v-img contain v-if="booking.finalDestinationValue" :src="`https://cdn.loglive.io/flags/4x3/${booking.finalDestinationValue.substring(0,2).toLowerCase()}.svg`" ></v-img>
                      </v-avatar>
                      {{ booking.finalDestinationValue }}
                    </v-chip>
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-chip v-if="selectedPallets.length > 0" class="mx-1" small outlined > Pallets: {{ selectedSSCC }} </v-chip>
                  <v-chip v-if="selectedPallets.length > 0" class="mx-1" small outlined > Cartons {{ selectedCartons }} </v-chip>
                  <v-chip v-if="selectedPallets.length > 0" class="mx-1" small outlined @click="updateStatusModal = true" > <v-icon small left>edit</v-icon> Update Status </v-chip>
                  <v-chip style="height: 40px" outlined small class="pr-1 text-center" >
                    <v-text-field placeholder="Search" class="mb-1" hide-details rounded clearable dense style="width: 300px" v-model="search" @change="updateSearchResult()" @input="updateSearchResult()" />
                  </v-chip>
                </v-toolbar>
                <v-row>
                  <v-col cols="12" sm="4" class="mb-0 pb-0">
                    <v-list dense>
                      <v-row>
                        <v-col cols="12" sm="6" class="mx-0 px-0">
                          <v-list-item :disabled="selectedPallets.length == 0" @click="processAllocation()" >
                            <v-list-item-action class="mx-0 px-0">
                              <v-icon color="grey" small >assignment_turned_in</v-icon >
                            </v-list-item-action>
                            <v-list-item-content class="ml-2">
                              <v-list-item-title>
                                Save Allocation
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ selectedSSCC }} Selected
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-progress-circular indeterminate color="primary" size="20" v-if="loadingAllocation" />
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item :disabled=" selectedSSCC == (selectedContainer.totalPallets || 20) " @click="autoAllocate()" >
                            <v-list-item-action class="mx-0 px-0">
                              <v-icon color="amber" small>auto_awesome</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="ml-2">
                              <v-list-item-title>
                                Auto Allocate (FIFO)
                              </v-list-item-title>
                              <v-list-item-subtitle v-if="selectedSSCC < (selectedContainer.totalPallets || 20)" >
                                {{ (selectedContainer.totalPallets || 20) - selectedSSCC }} Remaining
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-progress-circular indeterminate color="primary" size="20" v-if="loadingAutoAllocation" >
                              </v-progress-circular>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item :disabled="selectedSSCC == 0" @click="removeAllocation()" >
                            <v-list-item-action class="mx-0 px-0">
                              <v-icon color="red" small>cancel</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="ml-2">
                              <v-list-item-title>
                                Clear Allocated
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ selectedSSCC }} Selected
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-progress-circular indeterminate color="primary" size="20" v-if="loadingRemoveAllocation" >
                              </v-progress-circular>
                            </v-list-item-action>
                          </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6" class="mx-0 px-0">
                          <v-list-item>
                            <v-list-item-action class="mx-0 px-0">
                              <v-icon color="grey">filter_list</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="ml-1">
                              <v-list-item-title>
                                Display Allocated
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch v-model="displayAllocated" color="blue" @change="calculateDisplayData()" ></v-switch>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-action class="mx-0 px-0">
                              <v-icon color="grey">local_shipping</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="ml-1">
                              <v-list-item-title>
                                Allow Dual Load
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch v-model="allowDualLoad" color="blue" ></v-switch>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item @click="pasteSSCC = true">
                            <v-list-item-action class="mx-0 px-0">
                              <v-icon color="grey">content_paste</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="ml-1">
                              <v-list-item-title>
                                Paste SSCC's
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-list>
                  </v-col>
                  <v-col cols="12" sm="8" class="mx-0 px-0 pb-0 mb-0">
                    <v-row>
                      <v-col cols="12" sm="6" class="mx-0 px-0">
                        <ul style="font-size: 11px; list-style-type: none">
                          <li class="my-0 py-0">
                            <v-row justify="space-between">
                              <v-col>
                                <v-icon small class="mr-1" color="teal" >event</v-icon > Average Age
                              </v-col>
                              <v-col class="text-right">
                                <v-chip x-small v-if="displayData.length > 0">
                                  {{ averageAge }} Days
                                </v-chip>
                                <v-chip x-small v-else> N/A </v-chip>
                              </v-col>
                            </v-row>
                          </li>
                          <li class="my-0 py-0">
                            <v-row justify="space-between">
                              <v-col>
                                <v-icon small class="mr-1" color="blue-grey" >tag</v-icon > Total Pallets
                              </v-col>
                              <v-col class="text-right">
                                <v-chip x-small>
                                  {{ calculatePallets }}
                                </v-chip>
                              </v-col>
                            </v-row>
                          </li>
                          <li class="my-0 py-0">
                            <v-row justify="space-between">
                              <v-col>
                                <v-icon small class="mr-1" color="success" >check_circle_outline</v-icon >
                                Allocated
                              </v-col>
                              <v-col class="text-right">
                                <v-chip x-small>
                                  {{ calculateAllocatedPallets }}
                                </v-chip>
                              </v-col>
                            </v-row>
                          </li>
                          <li class="my-0 py-0">
                            <v-row justify="space-between">
                              <v-col>
                                <v-icon small class="mr-1" color="warning" >warning</v-icon >
                                Unallocated
                              </v-col>
                              <v-col class="text-right">
                                <v-chip x-small>
                                  {{ calculateUnallocatedPallets }}
                                </v-chip>
                              </v-col>
                            </v-row>
                          </li>
                        </ul>
                      </v-col>
                      <v-col cols="12" sm="6" class="text-center mx-0 px-0 mb-0 pb-0" >
                        <v-list dense subheader>
                          <v-subheader style="font-size: 14px">
                            Load Outs <v-spacer></v-spacer>
                            <v-btn color="primary" @click="createLoadOutInstruction()" :loading="savingLoadOut" small icon >
                              <v-icon>add_circle_outline</v-icon>
                            </v-btn>
                          </v-subheader>
                          <v-divider></v-divider>
                          <ul style="font-size: 11px; list-style-type: none" :key="loadOutKey" >
                            <li class="text-left" v-for="instruction in selectedContainer.loadOutInstructions" :key="instruction.id" >
                              <v-btn class="mr-2" color="grey" icon small @click="downloadDocument(instruction)" ><v-icon small>download</v-icon></v-btn>
                              {{ instruction.locationCode }} - V{{ instruction.version }} ({{ instruction.totalPallets || 20 }} Pallets)
                            </li>
                            <li v-if=" selectedContainer.loadOutInstructions.length == 0 " style="color: grey" >
                              No Load Out Instructions
                            </li>
                          </ul>
                        </v-list>
                        <v-chip color="red" class="mx-1 mt-1" small @click="resetFilters()" v-if="filteredValues.length > 0" >
                          <v-icon small left>filter_alt_off</v-icon> Clear
                          Filters
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-card-text style="max-width: 100%; overflow-x: auto" class="my-0 pt-0" >
                  <div :style="{ width: tableWidth }" class="mt-2" v-if="displayData.length > 0" >
                    <table :width="'100%'">
                      <div class="mt-1 pt-1" :style="{ width: '100%' }" v-if="displayData.length > 0" >
                        <td class="text-center mx-0 px-0" style=" cursor: grab; border-right: 1px solid grey; min-height: 20px; min-width: 20px; font-size: 11px; "
                          v-for="(header, index) in stockHeaders" :key="index" :width="header.width ?? '150px'" >
                          <div>
                            <v-row align="center" justify="center" class="py-2">
                              <b style="font-size: 12px">{{ header.text }}</b>
                              <v-btn v-if="!header.disableSort" class="ml-1" icon x-small @click="changeSortBy(header.value)" >
                                <v-icon v-if="sortBy != header.value" small color="grey" >arrow_upward</v-icon >
                                <v-icon v-else-if="sortDesc" small >arrow_downward</v-icon >
                                <v-icon v-else small>arrow_upward</v-icon>
                              </v-btn>
                              <v-badge v-if="header.filterable" :content=" filter[header.value] ? filter[header.value].length : false " :value=" filter[header.value] && filter[header.value].length > 0 " color="primary" offset-y="10" >
                                <v-menu offset-y left :close-on-content-click="false" >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" x-small color="grey" icon ><v-icon small>filter_alt</v-icon></v-btn >
                                  </template>
                                  <v-card style=" background: var( --v-modal-base ) !important; border-radius: 20px; " >
                                    <v-card-text>
                                      <v-list dense style=" background: var( --v-modal-base ) !important; border-radius: 20px; " >
                                        <v-text-field
                                          hide-details
                                          rounded
                                          dense
                                          outlined
                                          clearable
                                          prepend-inner-icon="search"
                                          placeholder="Search"
                                          autofocus
                                          v-model="searchHeaders[header.value]"
                                          @change="updateSearchKey()"
                                        ></v-text-field>
                                        <v-list dense style=" max-height: 50vh; overflow-y: auto; background: var( --v-modal-base ) !important; " :key="searchKey" >
                                          <v-list-item v-for="( item, index ) in headerOptions(header.value)" :key="index" >
                                            <v-list-item-action class="pr-0">
                                              <v-checkbox v-model="filter[header.value]" :value="item.value" hide-details dense @change="calculateDisplayData()" />
                                            </v-list-item-action>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                {{ item.name }}
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item v-if=" headerOptions(header.value) .length == 0 " >
                                            <v-list-item-content class="text-center" >
                                              <span style="font-size: 12px" >No results found</span >
                                            </v-list-item-content>
                                          </v-list-item>
                                        </v-list>
                                      </v-list>
                                    </v-card-text>
                                  </v-card>
                                </v-menu>
                              </v-badge>
                            </v-row>
                          </div>
                        </td>
                      </div>
                    </table>
                    <v-divider></v-divider>
                    <v-virtual-scroll v-if="displayData.length > 0" :bench="10" :items="displayData" :height="calculatedHeight" :width="'100%'" item-height="35" class="px-1" >
                      <template v-slot:default="{ item }">
                        <div class="text-center" :style="{ display: 'flex', 'flex-direction': 'row', width: '100%', height: '35px', 'background-color': item.id && selectedPallets.includes(item.id) ? 'rgba(114, 159, 217, 0.2)' : 'transparent', 'font-size': '11px', }" >
                          <td v-for="(header, index) in stockHeaders" :key="index" :style="{ width: header.width ?? '150px' }" class="text-center pt-1 mb-0 pb-0" >
                          <div v-if="header.value == 'actions'" class="tex†-center" >
                              <v-btn @click="selectPallet(item)" small v-if="itemSelected(item.id)" style="border: none" color="success" icon>
                                <v-icon small >check</v-icon>
                                </v-btn>
                                <v-btn small @click="selectPallet(item)" v-else icon style="border: 0.25px dotted grey" color="grey">
                                  <v-icon small>check</v-icon>
                                </v-btn>
                            </div>
                            <div v-else-if="header.value == 'status'" class="tex†-center" >
                              <v-chip v-if="item[header.value]" small outlined :color=" getPalletStatusColor(item[header.value]) " @click=" addToFilter(item[header.value], header.value) " style="font-size: 11px" >
                                {{ item[header.value] }}
                              </v-chip>
                            </div>

                            <div v-else class="text-center">
                              <v-chip v-if="item[header.value]" small class="primaryText--text" outlined @click=" addToFilter(item[header.value], header.value) " style="border: none; font-size: 11px" >
                                {{ item[header.value] }}
                              </v-chip>
                            </div>
                          </td>
                        </div>
                        <v-divider class="my-0 py-0"></v-divider>
                      </template>
                    </v-virtual-scroll>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <valhalla-loading :height="'65vh'" />
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="pasteSSCC" width="500px" >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Paste SSCC</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :disabled="!pasteData" icon color="secondary" @click="processPasteData()" >
            <v-icon> save </v-icon>
          </v-btn>
          <v-btn text @click="pasteSSCC = false"> X </v-btn>
        </v-toolbar>
        <v-card-text >
          <div v-if="pasteErrors" style="width: 100%">
           <span style="font-size: 12px">Error adding SSCC's: {{ pasteErrors }}
           </span>
          </div>
          <v-textarea placeholder="Paste SSCC's here" v-model="pasteData" outlined rows="12" ></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="updateStatusModal" width="500px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Update Status</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="updateStatusModal = false"> X </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense>
            <v-list-item v-for="(status, index) in palletStatus" :key="index" @click="updatePalletStatus(status.name)" >
              <v-list-item-action>
                <v-icon :color="status.color">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ status.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import draggable from "vuedraggable";

export default {
  props: ["orders", "booking", "refreshKey"],
  components: {
    // draggable,
  },
  data: () => ({
    allocated: [],
    allowDualLoad: false,
    containers: [],
    customers: [],
    displayAllocated: false,
    displayData: [],
    drag: false,
    filterPort: null,
    filterCustomer: null,
    filter: {},

    filterKey: 100,
    filteredValues: [],
    loading: false,
    loadedContainers: [],
    loadingAutoAllocation: false,
    loadingAllocated: false,
    loadingAllocation: false,
    loadOutKey: 200,
    loadingItems: false,
    loadingRemoveAllocation: false,
    loadingStock: false,
    orderKey: 500,
    orderItems: [],
    orderStatus: [
      { name: "Planned", icon: "schedule", color: "#F57C00" },
      { name: "In Progress", icon: "pending", color: "blue" },
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "Confirmed", icon: "verified", color: "teal" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      // { name: "Cancelled", icon: "cancel", color: "#F44336" }
    ],
    palletStatus: [
      { name: "In Stock", icon: "local_shipping", color: "orange" },
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "In Transit", icon: "local_shipping", color: "teal" },
      { name: "Rejected", icon: "verified", color: "orange" },
      { name: "Stuffed", icon: "check_circle_outline", color: "lime" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Removed", icon: "cancel", color: "grey" },
      { name: "Instruction Sent", icon: "email", color: "indigo" },
    ],
    productQuantityTypes: [
      { value: "BG", text: "Bag" },
      { value: "TT", text: "Bag, tote" },
      { value: "BA", text: "Barrel" },
      { value: "BL", text: "Bale" },
      { value: "BI", text: "Bin" },
      { value: "BX", text: "Box" },
      { value: "VL", text: "Bulk, liquid" },
      { value: "VR", text: "Bulk, solid" },
      { value: "BH", text: "Bunch" },
      { value: "BE", text: "Bundle" },
      { value: "CT", text: "Carton" },
      { value: "CS", text: "Case" },
      { value: "CN", text: "Container" },
      { value: "CR", text: "Crate" },
      { value: "DR", text: "Drum" },
      { value: "FB", text: "Flexibag" },
      { value: "JT", text: "Jutebag" },
      { value: "LU", text: "Lug" },
      { value: "PK", text: "Package" },
      { value: "PA", text: "Packet" },
      { value: "PX", text: "Pallet" },
      { value: "PT", text: "Pot" },
      { value: "PO", text: "Pouch" },
      { value: "RO", text: "Roll" },
      { value: "SH", text: "Sachet" },
      { value: "SA", text: "Sack" },
      { value: "SI", text: "Skid" },
      { value: "PU", text: "Tray" },
      { value: "VI", text: "Vial" },
    ],
    stockHeaders: [
      {
        text: "",
        value: "actions",
        width: "50px",
        disableSort: true,
      },
      {
        text: "Location",
        value: "locationCode",
        width: "100px",
        filterable: true,
      },
      {
        text: "Status",
        value: "status",
        width: "150px",
        filterable: true,
      },
      {
        text: "SSCC",
        value: "sscc",
        width: "150px",
        filterable: true,
      },
      {
        text: "Seq.",
        value: "sequenceNo",
        width: "75px",
      },
      {
        text: "Age",
        value: "age",
        width: "100px",
      },
      {
        text: "Commodity",
        value: "commodityCode",
        filterable: true,
      },
      {
        text: "Variety",
        value: "varietyCode",
        filterable: true,
      },
      {
        text: "Mark",
        value: "mark",
        filterable: true,
      },
      {
        text: "Pack",
        value: "packCode",
        filterable: true,
      },
      {
        text: "Grade",
        value: "grade",
        filterable: true,
      },
      {
        text: "PUC",
        value: "puc",
        filterable: true,
      },
      {
        text: "Orch.",
        value: "orchard",
        filterable: true,
      },
      {
        text: "PHC",
        value: "phc",
        filterable: true,
      },
      {
        text: "Cartons",
        value: "cartonQuantity",
      },
      {
        text: "Plt. Qty",
        value: "palletQuantity",
      },
    ],
    pasteData: null,
    pasteErrors: null,
    pasteSSCC: false,
    savingLoadOut: false,
    searchKey: 200,
    search: null,
    selectedOrder: {},
    selectedContainer: {},
    selectedPallets: [],
    sortBy: null,
    sortDesc: false,
    stock: [],
    searchCustomer: "",
    searchHeaders: {},
    selectedPalletKey: 500,
    updateStatusModal: false,
  }),
  watch: {
    allocated: {
      immediate: true,
      handler(val) {
        this.selectedContainer.assignedStock = [
          ...new Set(val.map((x) => x.sscc)),
        ].length;
        this.selectedContainer.assignedProgress = Math.round(
          (this.selectedContainer.assignedStock /
            this.selectedContainer.totalPallets ?? 20) * 100
        );
        this.selectedContainer.cartons = val
          .map((x) => x.cartonQuantity)
          .reduce((a, b) => a + b, 0);
      },
    },
    orders: {
      immediate: true,
      handler(val) {
        if (val && val.length > 0) {
          if (
            this.loadedContainers.length == 0 ||
            !this.loadedContainers.every((x) =>
              val.map((y) => y.id).includes(x)
            )
          ) {
            this.loadContainers(val.map((x) => x.id));
            this.loadedContainers = val.map((x) => x.id);
          }
        }
      },
    },
  },
  computed: {
    averageAge() {
      let result =
        this.displayData.map((x) => x.age).reduce((a, b) => a + b, 0) /
        this.displayData.length;
      return Math.round(result * 100) / 100;
    },
    calculateAllocatedPallets() {
      let result = this.displayData.filter((x) => x.bookingContainerId).length;
      return result;
    },
    calculateUnallocatedPallets() {
      let result = this.displayData.filter((x) => !x.bookingContainerId).length;
      return result;
    },
    calculatePallets() {
      let result = this.displayData.length;
      return result;
    },
    calculateCartons() {
      let result = this.displayData
        .map((x) => x.cartonQuantity)
        .reduce((a, b) => a + b, 0);
      return result;
    },
    calculatedHeight() {
      let height = window.innerHeight;
      return height * 0.42 + "px";
    },
    filterCustomers() {
      let result = [...this.customers];
      if (this.filterPort) {
        let findBookings = result.filter(
          (x) => x.portOfLoadValue == this.filterPort
        );
        let validCustomers = result.filter(
          (x) =>
            x.type == "customer" &&
            findBookings.some((y) => y.orderCustomerId == x.id)
        );
        let validBookings = result.filter(
          (x) => x.type == "order" && findBookings.some((y) => y.id == x.id)
        );
        let validContainers = result.filter(
          (x) =>
            x.type == "container" &&
            findBookings.some((y) => y.id == x.bookingId)
        );
        result = result.filter(
          (x) =>
            validCustomers.includes(x) ||
            validBookings.includes(x) ||
            validContainers.includes(x)
        );
      }
      if (this.filterCustomer) {
        let findBookings = result.filter(
          (x) =>
            x.type == "order" && x.orderCustomerId == this.filterCustomer.id
        );
        let validCustomers = result.filter(
          (x) => x.type == "customer" && findBookings.some((y) => y.id == x.id)
        );
        let validBookings = result.filter(
          (x) => x.type == "order" && findBookings.some((y) => y.id == x.id)
        );
        let validContainers = result.filter(
          (x) =>
            x.type == "container" &&
            findBookings.some((y) => y.id == x.bookingId)
        );
        result = result.filter(
          (x) =>
            validCustomers.includes(x) ||
            validBookings.includes(x) ||
            validContainers.includes(x)
        );
      }
      if (this.searchCustomer) {
        result = result.filter(
          (x) =>
            (x.type == "customer" &&
              x.name
                .toLowerCase()
                .includes(this.searchCustomer.toLowerCase())) ||
            (x.type == "order" &&
              x.orderNo
                .toLowerCase()
                .includes(this.searchCustomer.toLowerCase())) ||
            (x.type == "container" &&
              x.ctoNo.toLowerCase().includes(this.searchCustomer.toLowerCase()))
        );
      }
      return result;
    },
    filterOrders() {
      let result = this.orders;
      if (this.filterPort) {
        result = result.filter((x) => x.portOfLoadValue == this.filterPort);
      }
      if (this.filterCustomer) {
        result = result.filter(
          (x) => x.orderCustomerId == this.filterCustomer.id
        );
      }
      if (this.searchOrder) {
        result = result.filter((x) =>
          x.orderNo.toLowerCase().includes(this.searchOrder.toLowerCase())
        );
      }
      return result;
    },
    selectedCartons() {
      return this.stock
        .filter((x) => this.selectedPallets.includes(x.id))
        .map((x) => parseInt(x.cartonQuantity))
        .reduce((a, b) => a + b, 0);
    },
    selectedSSCC() {
      return [
        ...new Set(
          this.stock
            .filter((x) => this.selectedPallets.includes(x.id))
            .map((x) => x.sscc)
        ),
      ].length;
    },
    tableWidth() {
      let result = 0;
      this.stockHeaders.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 150);
      });
      return result + "px";
    },
  },
  created() {
    this.setFilter();
  },
  methods: {
    addToFilter(value, header) {
      if (this.filter[header] && this.filter[header].includes(value)) {
        this.filter[header] = this.filter[header].filter((x) => x != value);
      } else {
        this.filter[header].push(value);
      }
      this.filterKey++;
      // this.selectedPallets = [];
      console.log("Selected pallets", this.selectedPallets);
      this.calculateDisplayData();
    },
    autoAllocate() {
      this.loadingAutoAllocation = true;
      let remaining =
        (this.selectedContainer.totalPallets || 20) -
        this.selectedSSCC;
      let sliceResult = [...this.displayData];
      let allowedStores = [];
      let uniqueStores = [
        ...new Set(this.displayData.map((x) => x.locationCode)),
      ];
      let calculatedStores = [];
      for (let i = 0; i < uniqueStores.length; i++) {
        let store = this.displayData.filter(
          (x) => x.locationCode == uniqueStores[i]
        );
        let averageAge =
          store.map((x) => x.age).reduce((a, b) => a + b, 0) / store.length;
        calculatedStores.push({ id: uniqueStores[i], age: averageAge });
      }
      calculatedStores.sort((a, b) => {
        return a.age < b.age ? 1 : -1;
      });
      allowedStores =
        calculatedStores.length > 1
          ? [calculatedStores[0].id, calculatedStores[1].id]
          : calculatedStores.map((x) => x.id);
      if (this.allowDualLoad) {
        sliceResult = sliceResult.filter((x) =>
          allowedStores.includes(x.locationCode)
        );
      } else {
        sliceResult = sliceResult.filter(
          (x) => x.locationCode == allowedStores[0]
        );
      }
      sliceResult.sort((a, b) => {
        return a.age < b.age ? 1 : -1;
      });
      this.selectedPallets = [
        ...this.selectedPallets,
        ...sliceResult.slice(0, remaining).map((x) => x.id),
      ];
      this.loadingAutoAllocation = false;
      this.processAllocation();
    },
    calculateDisplayData() {
      let result = [...this.stock];
      let appliedfilter = Object.keys(this.filter).filter(
        (x) => this.filter[x].length > 0
      );
      this.filteredValues = appliedfilter;
      for (let i = 0; i < appliedfilter.length; i++) {
        if (appliedfilter[i] == "grade") {
          result = result.filter(
            (x) =>
              this.filter[appliedfilter[i]].includes(x.formattedClass) ||
              this.filter[appliedfilter[i]].includes(x.grade)
          );
        } else {
          result = result.filter((x) =>
            this.filter[appliedfilter[i]].includes(x[appliedfilter[i]])
          );
        }
      }
      if (!this.displayAllocated) {
        result = result.filter((x) => !x.bookingContainerId);
      } else {
        result = result.filter((x) => x.bookingContainerId || this.selectedPallets.includes(x.id));
      }
      if (this.search) {
        result = result.filter((x) => x.sscc.includes(this.search));
      }
      if (this.sortBy) {
        result = result.sort((a, b) => {
          if (this.sortDesc) {
            return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
          } else {
            return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
          }
        });
      }
      this.displayData = result;

      this.filterKey++;
    },
    changeSortBy(value) {
      if (this.sortBy == value) {
        if (this.sortDesc) {
          this.sortBy = null;
          this.sortDesc = false;
        } else {
          this.sortDesc = true;
        }
      } else {
        this.sortBy = value;
        this.sortDesc = false;
      }
      this.calculateDisplayData();
    },
    async createLoadOutInstruction() {
      try {
        this.savingLoadOut = true;
        let result = await this.$API.createLoadOutInstructions(
          this.selectedContainer.id
        );
        this.selectedContainer.loadOutInstructions = result;
        let findIndex = this.orderItems.findIndex(
          (x) => x.id == this.selectedContainer.id
        );
        this.orderItems[findIndex].loadOutInstructions = result;
        this.$message.success("Successfully generated load out instruction!");
        this.loadOutKey++;
        this.savingLoadOut = false;
      } catch (e) {
        console.log(e);
        this.savingLoadOut = false;
      }
    },
    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.key.split("/").at(-1);
      hiddenElement.click();
    },
    formatBaseUnit(type) {
      let result = this.productQuantityTypes.find((x) => x.value == type);
      return result ? result.text : "";
    },
    async sendLoadOutInstruction() {
      try {
        this.savingLoadOut = true;
        await this.$API.sendLoadOutInstruction(this.selectedContainer.id);
        this.savingLoadOut = false;
      } catch (e) {
        console.log(e);
        this.savingLoadOut = false;
      }
    },
    getPalletStatusColor(status) {
      let find = this.palletStatus.find((x) => x.name == status);
      return find ? find.color : "grey";
    },
    getPalletStatusIcon(status) {
      let find = this.palletStatus.find((x) => x.name == status);
      return find ? find.icon : "help";
    },
    headerOptions(value) {
      let result = [
        ...new Set(
          this.stock
            .filter((x) => x[value])
            .map((x) => ({ name: x[value], value: x[value] }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      if (this.searchHeaders[value]) {
        uniqueValues = uniqueValues.filter(
          (x) =>
            x &&
            x.toLowerCase().includes(this.searchHeaders[value].toLowerCase())
        );
      }
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      return result;
    },
    itemSelected(id){
      let result = this.selectedPallets.includes(id)
      return result
    },
    locationCodeCount(code) {
      return this.allocated.filter((x) => x.locationCode == code).length;
    },
    async loadContainers(orders) {
      this.loading = true;
      this.loadingItems = true;
      this.orderItems = await this.$API.getAllManagedOrderContainers({
        batch: orders,
      });
      this.orderItems.forEach((x) => {
        x.assignedProgress = (x.assignedStock / (x.totalPallets || 20)) * 100;
      });
      let uniqueCustomers = [
        ...new Set(this.orders.map((x) => x.orderCustomerId)),
      ];
      let data = [];
      uniqueCustomers = uniqueCustomers
        .map((x) => this.orders.find((y) => y.orderCustomerId == x))
        .map((x) => x.orderCustomer)
        .filter(Boolean);
      for (let i = 0; i < uniqueCustomers.length; i++) {
        let findCustomer = this.orders.find(
          (x) => x.orderCustomerId == uniqueCustomers[i].id
        );
        data.push({
          ...findCustomer.orderCustomer,
          type: "customer",
        });
        let orders = this.orders.filter(
          (x) => x.orderCustomerId == uniqueCustomers[i].id
        );
        orders.forEach((x) => {
          let containers = this.orderItems.filter((y) => y.bookingId == x.id);
          data.push({
            ...x,
            type: "order",
          });
          containers.forEach((y) => {
            y.type = "container";
            y.orderCustomerId = uniqueCustomers[i].id;
          });
          data = [...data, ...containers];
        });
      }
      this.customers = data;
      if (this.orderItems.length > 0) {
        this.setActiveContainer(this.orderItems[0]);
      }
      this.loadingItems = false;
      this.loading = false;
    },
    async loadOrderStock() {
      this.loading = true;
      let data = await this.$API.getManagedOrderAllocations({
        orders: this.orders.map((x) => x.id),
      });
      this.orderData = data;
      this.loading = false;
    },
    async loadRelatedStock() {
      this.loadingStock = true;
      this.selectedPallets = [];
      let data = await this.$API.getRelatedManagedStock(
        this.selectedContainer.id
      );
      this.stock = data;
      this.selectedPallets = this.stock
        .filter((x) => x.bookingContainerId == this.selectedContainer.id)
        .map((x) => x.id);
        this.selectedPalletKey ++;
        console.log("Allocated pallets", this.stock
        .filter((x) => x.bookingContainerId == this.selectedContainer.id));
        console.log(this.selectedPallets)
      this.calculateDisplayData();
      this.loadingStock = false;
    },
    async processAllocation() {
      try {
        this.loadingAllocation = true;
        let filtered = this.stock.filter((x) =>
          this.selectedPallets.includes(x.id)
        );
        let result = await this.$API.allocateManagedOrder(
          this.selectedContainer.id,
          {
            stock: this.selectedPallets,
            orderId: this.selectedContainer.bookingId,
          }
        );
        let findIndex = this.orderItems.findIndex(
          (x) => x.id == this.selectedContainer.id
        );
        this.orderItems[findIndex].assignedStock = [
          ...new Set(result.map((x) => x.sscc)),
        ].length;
        this.orderItems[findIndex].assignedProgress = Math.round(
          (this.orderItems[findIndex].assignedStock /
            (this.orderItems[findIndex].pallets || 20)) *
            100
        );
        this.orderItems[findIndex].cartons = result
          .map((x) => x.cartonQuantity)
          .reduce((a, b) => a + b, 0);
        let remove = this.stock.filter(
          (x) =>
            x.bookingContainerId == this.selectedContainer.id &&
            !this.selectedPallets.includes(x.id)
        );
        remove.forEach((x) => {
          x.bookingContainerId = null;
          x.bookingContainer = null;
        });
        filtered.forEach((x) => {
          x.bookingContainerId = this.selectedContainer.id;
          x.bookingContainer = this.selectedContainer;
          x.status = "Allocated";
        });
        this.loadingAllocation = false;
        this.$message.success("Successfully allocated pallets!");
      } catch (e) {
        console.log(e);
        this.$message.error("An error ocurred!");
        this.loadingAllocation = false;
      }
    },
    processPasteData() {
      let data = this.pasteData.split("\n");
      let pasteErrors = [];
      let count = 0;
      for (let i = 0; i < data.length; i++) {
        let sscc = data[i].trim();
        let pallet = this.stock.filter((x) => x.sscc == sscc);
        if (
          pallet.length>0 &&
          this.selectedSSCC <
            (this.selectedContainer.totalPallets || 20) &&
          !this.selectedPallets.includes(pallet.id)
        ) {
          this.selectedPallets = [...pallet.map(x=>x.id), ...this.selectedPallets];
          count += 1;
        } else {
          pasteErrors.push(sscc);
        }
      }
      this.pasteErrors = pasteErrors.join(", ");
      this.$message.success(count + " pallets added!");
    },
    async removeAllocation() {
      this.loadingRemoveAllocation = true;
      await this.updatePalletStatus("In Stock");
      this.selectedPallets = [];
      await this.processAllocation();
      this.loadingRemoveAllocation = false;
    },
    productSpec(item) {
      let keys = [
        { key: "productId", value: "Product" },
        { key: "varietyProductId", value: "Variety" },
        { key: "puc", value: "PUC" },
        { key: "phc", value: "PHC" },
        { key: "packCode", value: "PACK" },
        { key: "grade", value: "Grade" },
        { key: "countSize", value: "Count" },
        { key: "inventoryCode", value: "Inventory Code" },
      ];
      let result = [];
      for (let i = 0; i < keys.length; i++) {
        if (keys[i].key == "productId" && item.product) {
          result.push(item.product.name);
        } else if (keys[i].key == "varietyProductId" && item.varietyProduct) {
          result.push(item.varietyProduct.name);
        } else if (item[keys[i].key]) {
          result.push(keys[i].value + ": " + item[keys[i].key]);
        }
      }
      return result.join(" - ");
    },
    resetFilters() {
      this.setFilter();
      this.calculateDisplayData();
    },
    setFilter() {
      let values = this.stockHeaders.filter((x) => x.filterable).map((x) => x.value);
      this.filter = {};
      values.forEach((x) => {
        this.searchHeaders[x] = null;
        this.filter[x] = [];
      });
    },
    setFilterCustomer(customer) {
      this.filterCustomer = customer;
      this.orderKey++;
    },
    setFilterPort(port) {
      this.filterPort = port;
      this.orderKey++;
    },
    setProductFilter(item) {
      this.setFilter();
      let filterableValues = [
        "puc",
        "phc",
        "locationCode",
        "ageStatus",
        "mark",
        "packCode",
        "grade",
        "targetMarket",
        "palletBaseType",
        "inventoryCode",
        "countSize",
        "commodityCode",
        "varietyCode",
      ];
      for (let value of filterableValues) {
        if (value == "varietyCode" && item.varietyProduct) {
          this.addToFilter(item.varietyProduct.code, value);
        } else if (item[value]) {
          this.addToFilter(item[value], value);
          // let findValues = item.orderProductSpecifications.filter(
          //   (x) => x.key == value
          // );
          // findValues.forEach((x) => {

          // });
        }
      }
    },
    statusColor(status) {
      if (status) {
        let color = this.orderStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return color ? color.color : "grey";
      } else return "grey";
    },
    statusIcon(status) {
      if (status) {
        let find = this.orderStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return find ? find.icon : "";
      } else return "help";
    },
    async setActiveContainer(container) {
      this.selectedContainer = container;
      this.loadOutKey++;
      await this.loadRelatedStock();
    },
    selectPallet(item){
      let findSSCC = this.stock.filter(x => x.sscc == item.sscc).map(x => x.id);
      if(this.selectedPallets.includes(item.id)){
        this.selectedPallets = this.selectedPallets.filter(x => !findSSCC.includes(x));
      } else {
        this.selectedPallets = [...this.selectedPallets, ...findSSCC]
      }
      this.selectedPalletKey ++
    },
    async updatePalletStatus(status) {
      try {
        this.loadingStatus = true;
        await this.$API.updateStockPalletStatus(this.selectedPallets, status);
        this.selectedContainer.status = status;
        let filtered = this.stock.filter((x) =>
          this.selectedPallets.includes(x.id)
        );
        filtered.forEach((x) => {
          x.status = status;
        });
        this.loadingStatus = false;
        this.updateStatusModal = false;
        this.$message.success("Successfully updated pallet status!");
      } catch (e) {
        console.log(e);
        this.loadingStatus = false;
      }
    },
    updateSearchKey() {
      this.searchKey++;
    },
    updateSearchResult() {
      this.calculateDisplayData();
    },
    viewOrder(item) {
      this.$router.push({
        path: "/order/" + item.systemReference,
      });
    },
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 550px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerMessage {
  width: 100%;
  height: 550px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-fixed thead th {
  position: sticky;
  z-index: 2;
  position: -webkit-sticky;
  top: -17px;
  background: #444444;
  color: #fff;
}
.innerContent {
  max-height: calc(100vh - 36px - 30px) !important;
  height: 100%;
  width: 100%;
  contain: content;
  overflow-y: scroll;
}
</style>